<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <Spinner v-if="isLoading"></Spinner>
        <template v-if="!isLoading && carData">
          <Toolbar class="p-mb-4">
            <template #start>
              <div style="font-size: 1.4em; color: inherit">{{ $t('Car') }}:
                <span v-if="carData">
                  <span v-if="carData.make">{{ carData.make.name || ''}}</span> <span v-if="carData.model">{{ carData.model.name || ''}}</span><span v-if="carData.plate_number">, {{ carData.plate_number || ''}}</span>
                </span>
              </div>
            </template>
            <template #end>
              <Button
                  v-if="carData"
                  label="Edit car"
                  icon="ti-pencil"
                  @click="editItem"
                  class="p-button-success p-button-raised"/>
            </template>
          </Toolbar>

          <TabMenu :model="tabMenuItems" class="p-p-1"/>
          <router-view @update-items="updateItems" :car="carData" @change-customer-status="changeCustomerStatus"/>

          <CarModal :visible="itemModal"
                    :item="item"
                    @update-items="updateItems"
                    @close="closeItemModal">
          </CarModal>
        </template>
        <div v-else-if="!carLoadingStarted && !carData" class="p-text-center p-m-2 p-p-6">{{ $t('No data available') }}</div>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import CarModal from '@/pages/cars/components/CarModal'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ httpMixins ],
  components: { CarModal },
	data() {
		return {
      itemModal: false,
      item: {},
      taxes: [],
      isLoading: true,
      carData: null,
      carLoadingStarted: false,
      settings,
      tabMenuItems: [],
      constants
    }
	},
  watch: {
    '$route'() {
      const route = this.$route.path.split('/')
      if (this.$route.path === '/login' || route.indexOf('cars') === -1 || route.indexOf(this.$route.params.id) === -1) {
        return false
      }

      if (!this.carData) {
        if (this.$route.params.id) {
          this.getCarData()
        }
      } else {
        if (this.$route.params.id && +this.$route.params.id !== this.carData.id) {
          this.getCarData()
        }
      }
    },
    '$i18n.locale'() {
      if (this.carData) {
        this.getTabMenu()
      } else {
        if (!this.carLoadingStarted && this.$route.params.id) {
          this.getCarData()
        }
      }
    },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.itemModal) {
          this.closeItemModal()
        }
        if (this.confirmItemDeleteModal) {
          this.closeConfirmItemDeleteModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    if (!this.$route.params.id) return false
    this.getCarData()
	},
  methods: {
    changeCustomerStatus(newStatus, userId) {
      if (!userId) return false
      if (this.carData?.user_id && this.carData.user_id === userId) {
        if (this.carData.owner.customerStatus) {
          this.carData.owner.customerStatus.status_id = newStatus
        } else {
          this.carData.owner.customerStatus = {}
          this.carData.owner.customerStatus.status_id = newStatus
        }
      }
    },
    editItem() {
      if (this.carData) {
        this.item = this.carData
        this.itemModal = true
        this.$store.commit('toggleFirstLayer', true)
      }
    },
    updateItems() {
      console.log('update items')
      if (this.$route.params.id) {
        this.getCarData(false)
      }
    },
    closeItemModal() {
      this.itemModal = false
      if (this.$store.state.firstLayerIsOpened === true ) {
        this.$store.commit('toggleFirstLayer', false)
      }
      this.item = {}
      this.submitted = false
    },
    getTabMenu() {
      this.tabMenuItems = [
        {label: this.$t('General information'), icon: 'ti-car', to: `/cars/${this.$route.params.id}/overview`},
        {label: this.$t('Orders'), icon: 'ti-hummer', to: `/cars/${this.$route.params.id}/orders`},
        {label: this.$t('Offers'), icon: 'ti-clipboard', to: `/cars/${this.$route.params.id}/offers`},
        {label: this.$t('Goods'), icon: 'ti-shopping-cart', to: `/cars/${this.$route.params.id}/goods`},
      ]

      // this.tabMenuItems[0].label = this.$t('General information')
      if (this.carData && this.carData.ordersCount) {
        this.tabMenuItems[1].label += ` (${this.carData.ordersCount || 0})`
      }
      if (this.carData && this.carData.offersCount) {
        this.tabMenuItems[2].label += ` (${this.carData.offersCount || 0})`
      }
      if (this.carData && this.carData.goodsCount) {
        this.tabMenuItems[3].label += ` (${this.carData.goodsCount || 0})`
      }
    },
    async getCarData(showSpinner = true) {
      this.carLoadingStarted = true
      this.isLoading = showSpinner
      try {
        const { data, status } = await httpClient.post('car/get-car-by-id?expand=creator,ordersCount,offersCount,goodsCount,owner,owner.customerStatus', { carId: this.$route.params.id })
        if (status === 200 && data) {
          this.carData = data
          this.getTabMenu()
        } else {
          this.carData = null
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.carLoadingStarted = false
        this.isLoading = false
      }
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>